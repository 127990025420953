<template>
  <b-container fluid class="bg-white py-5 documentation">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row v-if="!loading" class="bg-white">
      <b-col>
          <b-container class="bg-white">
            <b-row>
              <b-col>
                <p>
                  <b-link class="mr-4" :to="{ name: 'Docs' }">documentation</b-link>
                  <b-button class="mr-4" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'doc' }}">Edit</b-button>
                </p>
              </b-col>
              <b-col>
                <div>
                  status: <strong>{{step.name}}</strong>
                  <span v-if="!savingStep && permission.edit">
                    <b-button class="mx-4" v-if="step.downStep && permissionWorkflowDown" variant="outline-primary" size="sm" @click="stepUpdate(step.down)">move to: {{step.downStep.name}}</b-button>
                    <b-button v-if="step.upStep && permissionWorkflowUp" variant="outline-primary" size="sm" @click="stepUpdate(step.up)">move to: {{step.upStep.name}}</b-button>
                  </span>
                  <span v-if="savingStep">
                    <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                  </span>
                </div>
                <div v-if="item.workflow_created_by"><small>{{item.workflow_created_by}} at {{moment(item.workflow_created_at).format('YYYY-MM-DD HH:mm')}}</small></div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <div class="mb-3" v-for="row in rows" :key="row.id">
                  <b-link :to="{ name: 'Doc', params: { id: row.id } }">{{row.name}}</b-link>
                </div>
              </b-col>
              <b-col>
                <h1 class="my-4 ml-0 pl-0">
                  {{item.name}}
                </h1>
                <div v-html="item.body"></div>
              </b-col>
            </b-row>
          </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import workflow from '@/workflows/initiative'

export default {
  name: 'Doc',
  components: {
  },
  computed: {
    permissionWorkflowDown: function () {
      let result = false
      if (this.step.id > 7 && ac.can(this.user.acgroups).createAny('doc').granted) {
        result = true
      }
      if (this.step.id <= 7 && ac.can(this.user.acgroups).createAny('doc').granted) {
        result = true
      }
      return result
    },
    permissionWorkflowUp: function () {
      let result = false
      if (this.step.id >= 7 && ac.can(this.user.acgroups).createAny('initiativeWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id < 7 && ac.can(this.user.acgroups).createAny('impactinitiative').granted) {
        result = true
      }
      return result
    },
    step: function () {
      const step = workflow.find(x => x.id === this.item.workflow_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permission.edit = ac.can(this.user.acgroups).updateAny('doc').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      item: null,
      moment: moment,
      permission: {
        delete: false,
        edit: false
      },
      rows: [],
      savingStep: false
    }
  },
  methods: {
    load: async function () {
      this.$stat.log({ page: 'doc', action: 'open doc', model: 'doc', model_id: this.$route.params.id })
      this.loading = true
      try {
        const apiName = 'cosmos'
        const path = `/standard/doc/item/${this.$route.params.id}`
        this.item = await this.$Amplify.API.get(apiName, path)
        document.title = this.item.name
        this.rows = await this.$Amplify.API.get('cosmos', '/docs/all')
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    stepUpdate: async function (id) {
      this.savingStep = true
      try {
        const params = {
          body: {
            workflow_id: id,
            workflow_created_at: moment().toISOString(),
            workflow_created_by: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/doc/${this.item.id}`, params)
        this.item.workflow_id = id
        this.item.workflow_created_by = params.body.workflow_created_by
        this.item.workflow_created_at = params.body.workflow_created_at
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.savingStep = false
    }
  },
  watch: {
    '$route.params.id': function () {
      this.load()
    }
  }
}
</script>

<style>
.documentation h2 {
  font-size: 1.7em;
  font-weight: 500;
  margin-top: 2em;
  margin-bottom: 1.2em;
}
.documentation h3 {
  font-size: 1.2em;
  font-weight: 600;
  margin-top: 2em;
  margin-bottom: 1.2em;
}
</style>
